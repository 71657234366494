import "../styles/MenuSection.scss";
import TitleDivider from "../components/TitleDivider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Button from "../components/Button";

export function renderFullMenu(menu) {
    let fullMenu = [];

    //loop through menu json and create a list of MenuSection components
    for (let i = 0; i < menu.length; i++) {
        fullMenu.push(
            <MenuSection
                key={i}
                day={menu[i].day}
                restaurantName={menu[i].restaurantName}
                menuLink={menu[i].menuLink}
                lunchLink={menu[i].lunchLink}
                dinnerLink={menu[i].dinnerLink}
                menuPrice={menu[i].menuPrice}
                menuDescription= {menu[i].menuDescription}
                menuImage={menu[i].menuImage}
                special={menu[i].special}
            />
        );
    }

    return ( 
        <div className="menu-weekly-container">
            {fullMenu}
        </div>
    );
}

export function MenuSection(props) {
    return(
        <div className="menu-section">
            <h4 class={`menu-day ${props.special ? "green" : "white" }`}>{props.day}</h4>
            <a className="menu-img" href={props.menuLink} target="_blank" rel="noreferrer">
                <img src={props.menuImage} alt={props.restaurantName}/> 
            </a>
            <div className="menu-info">
                <h4 className="white">{props.restaurantName} | ${props.menuPrice} </h4>
                <p>
                    <a href={props.menuLink} target="_blank" rel="noreferrer" className="white">Menu</a> 
                    <span className="white"> &nbsp;&nbsp;|&nbsp;&nbsp; </span> 
                    <a href={props.lunchLink} target="_blank" rel="noreferrer" className="green">Lunch</a>
                    <span className="white"> &nbsp;&nbsp;|&nbsp;&nbsp; </span> 
                    <a href={props.dinnerLink} target="_blank" rel="noreferrer" className="green">Dinner</a>
                </p>
            </div>
        </div>
    );
}

MenuSection.defaultProps = {
    day:"Monday",
    restaurantName: "Aloha Hawaiian BBQ",
    menuLink: "https://tinyurl.com/fp-order",
    lunchLink: "https://tinyurl.com/fp-order",
    dinnerLink: "https://tinyurl.com/fp-order",
    menuPrice: "9-12",
    menuDescription: "Fried & charbroiled seafood plus chicken & fish tacos",
    menuImage: "https://nickthegreek.square.site/uploads/1/3/1/6/131614229/s346000858714731920_p236_i1_w3837.jpeg?width=640",
    special: false
}