import "../styles/Button.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faLink } from '@fortawesome/free-solid-svg-icons';

export default function Button(props){
    return(
        <a style={props.style} className="button" href={props.link} target="_blank" rel="noreferrer">
           <h4>{props.text}</h4> 
           {/* <FontAwesomeIcon
                style={{height: "2rem"}}
                icon={faChevronRight}
                color="#65c892"
            /> */}
        </a>
    );
}