import "../styles/Footer.scss";
import Divider from "./Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import MailForm from "./MailForm";
import { motion } from "framer-motion";
import {fade } from "../Animations";

export default function Footer() {
    return (
        <div className="footer">
            <Divider/>
            <div className="footer-contact white">
                <div style={{ fontWeight: "bold" }}>contact us</div>
                <div> <FontAwesomeIcon icon={faEnvelopeSquare} /> info@foodpool.app</div>
                <div> <FontAwesomeIcon icon={faInstagram} /> <a href="https://www.instagram.com/foodpool.app/" target="_blank" rel="noreferrer" style={{color:"white"}}>@foodpool.app</a></div>
            </div>
            {/* <br/>
            <h4 className="white">join our mailing list</h4>
            <MailForm />
            <br/>
            <br/> */}

        </div>
    );
}