export { fade, fade_pop, slide_right, bounce_down, bounce_up, slide_fade, spin_slide_right };

const fade = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0 }
};

const fade_pop = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.5 }
};

const slide_right = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 }
};

const slide_fade = (delay) => {
    return (
        {
            hidden: { opacity: 0, x: -10 },
            visible: {
                opacity: 1, x: 0,
                transition: {
                    delay: delay,
                    duration: 0.3,
                }
            },
        }
    );
};

const line_draw = {
    hidden: { width:"0%" },
    visible: { width:"25%", transition: { duration: 0.3 } }
}

const spin_slide_right = (delay, duration) => {
    return (
        {
            hidden: { opacity: 0, x: -100, rotate: -45 },
            visible: {
                opacity: 1, x: 0,
                rotate: 0,
                transition: {
                    delay: delay,
                    duration: duration,
                }
            },
        }
    );
};

const bounce_down = {
    visible: {
        y: [0, 5, 0, 5, 0]
    },
    hidden: { y: 0 }
}

const bounce_up = {
    visible: {
        y: [5, 0, 5, 0, 5]
    },
    hidden: { y: 0 }
}