import "../styles/Main.scss"
import "../styles/HeroImage.scss"

import FoodExplosion from "../images/food_bg_cropped.png"
import RestaurantCarousel from "../images/restaurant_carousel.png"
import FoodpoolLogo from "../images/foodpool_logo__icon_white_ai_1.svg"

export default function HeroImage(props) {    
    if(props.version == "A"){ // new bg (MOBILE ONLY)
        return (
            <div className="hero-images">
                {/* <img src={FoodExplosion} alt="" className="hero-background desktop-only"/> */}
                <img src={RestaurantCarousel} alt="" className="hero-background restaurant-carousel-temp"/>
                <img src={FoodpoolLogo} alt="" className="hero-logo floating" />
            </div>
        );
    }
    else { // old bg
        return (
            <div className="hero-images">
                <img src={FoodExplosion} alt="" className="hero-background"/>
                <img src={FoodpoolLogo} alt="" className="hero-logo floating" />
            </div>
        );
    }
}