import { motion } from "framer-motion";
import MailForm from "../components/MailForm";
import WaveDivider from "../components/WaveDivider";
import { fade } from "../Animations.js";
import Button from "../components/Button";
import HeroImage from "../components/HeroImage";
import { Version, trackEnterLanding, trackExitLanding } from '../analytics/landing.js';
import { useEffect } from 'react';

import Launchpad from "../images/launchpad_1.png";
import Antcenter from "../images/antcenter_1.png";
import BBComp from "../images/bbcomp.png";

import "../styles/Landing.scss";
import "../styles/Main.scss"

function randomLanding() {
    if(Math.random() > 0.5){
        return { content: <HeroImage version="A"/>, version: Version.A };
    } else {
        return { content: <HeroImage version="B"/>, version: Version.B };
    }
}

export default function Landing() {
    let { content, version } = randomLanding();

    // // hacky enter exit mixpanel code
    // useEffect(() => {
    //     trackEnterLanding(version);
    //     window.addEventListener('beforeunload', alertUser);
    // }, []);

    // // https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
    // const alertUser = e => {
    //     // e.preventDefault();
    //     // e.returnValue = '';
    //     trackExitLanding(version);
    // }

    return (
        <div className="main">
            <div className="section">
                {/* <HeroImage/> */}
                { content }
                <h1 className="white"> Study fuel for busy students. </h1>
                <h2 className="white">
                    Tasty scheduled meals, <span className="green"> delivered for only $2</span>
                </h2>

                {/* <Button text="Order online" link="/menu" style={{ width: "60%", marginTop:"1.0rem", textDecoration:"" }} />
                <p className="white"> Now delivering to ACC, UTC, Park West, and on-campus housing.</p> */}

                {/* <h2 className="white">join the <span className="green">waitlist</span></h2>
                <MailForm /> */}

                <div className="partners">
                    <a href="https://antrepreneur.uci.edu/" target="_blank" rel="noreferrer">
                        <img src={Antcenter} alt="UCI ANTrepreneuer Center" className="partner-image"/>
                    </a>
                    <a href="https://www.blackstonelaunchpad.org/" target="_blank" rel="noreferrer">
                        <img src={Launchpad} alt="Blackstone Launchpad"  className="partner-image"/>
                    </a>
                    <a href="https://bbcomp.tech.uci.edu/" target="_blank" rel="noreferrer">
                        <img src={BBComp} alt="UCI Beall and Butterworth" className="partner-image"/>
                    </a>
                </div>

            </div>
            <WaveDivider />


            <div className="section" id="info">
            <h2>How it works</h2>
            <motion.hr
                    initial="hidden"
                    whileInView="visible"
                    variants={fade}
                    viewport={{ once: true }}
                    style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
                />
            <p className="white" style={{maxWidth: "50%", minWidth:"300px"}} >We deliver food from 12:30-1pm and 6:30-7pm every week. Order ahead on our <a href="/menu" target="_blank" rel="noreferrer" className="white"> weekly menu</a>, and we'll drop it off at your doorstep during the window.</p>
            </div>

            <div className="card-container">
                <div className="info-card card-1">
                <h3>Affordable</h3>
                    <motion.hr
                        initial="hidden"
                        whileInView="visible"
                        variants={fade}
                        viewport={{ once: true }}
                        style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
                    />
                    <p>
                        Just the food + $2. <span className="white">No markups, no service fees, and no tips. Get that extra deep focus time without breaking the bank.</span>
                    </p>
                </div>

                <div className="info-card card-2">
                    <h3>Curated</h3>
                    <motion.hr
                        initial="hidden"
                        whileInView="visible"
                        variants={fade}
                        viewport={{ once: true }}
                        style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
                    />
                    <p>
                        Hand-picked restaurants every week,
                        <span className="white"> including local favorites and hidden gems like <a href="https://www.raisingcanes.com/" target="_blank" rel="noreferrer" className="white">Raising Cane's</a> and <a href="https://crumblcookies.com/" rel="noreferrer" target="_blank" className="white">Crumbl Cookies</a>.
                        </span>
                    </p>
                </div>

                <div className="info-card card-3">
                <h3>Scheduled</h3>
                    <motion.hr
                        initial="hidden"
                        whileInView="visible"
                        variants={fade}
                        viewport={{ once: true }}
                        style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
                    />
                    <p>
                        Same time every day.
                        <span className="white"> Everyone's meal comes in one trip to keep it affordable and carbon-friendly. Plan around your busiest days. </span>
                    </p>
                </div>
            </div>

            <div className="section">
                    <h2>Check out our menu for the week!</h2>
                    <motion.hr
                        initial="hidden"
                        whileInView="visible"
                        variants={fade}
                        viewport={{ once: true }}
                        style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
                    />
                    <Button text="See our menu" link="/menu" style={{ width: "60%", marginTop:"1.0rem", textDecoration:"" }} />
            </div>

        </div >
    );
}
