import mixpanel from 'mixpanel-browser';

const ENTER_EVENT = 'Enter Landing Menu';
const EXIT_EVENT = 'Exit Landing Page';

export const Version = {
    A : 'A',
    B : 'B',
};

function makeEventName(eventType, version) {
    return eventType.concat(' ', version);
}

export function trackEnterLanding(version) {
    console.log("enter");
    mixpanel.track(makeEventName(ENTER_EVENT, version));
}

export function trackExitLanding(version) {
    console.log("exit");
    mixpanel.track(makeEventName(EXIT_EVENT, version));
}
