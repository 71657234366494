import { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, onAuthStateChanged } from "firebase/auth";
import "../styles/Dashboard.scss";

// https://stackoverflow.com/questions/57847626/using-async-await-inside-a-react-functional-component
export default function Dashboard() {

    const firebaseConfig = {
        apiKey: "AIzaSyAQ3kZWky5Guyhkv-4qjuGjVuQixOnwZI4",
        authDomain: "onthego-sanity.firebaseapp.com",
        projectId: "onthego-sanity",
        storageBucket: "onthego-sanity.appspot.com",
        messagingSenderId: "271981332884",
        appId: "1:271981332884:web:0052e6a50591a383cb2781"
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();

    const [isAuthed, setIsAuthed] = useState(false);
    const [data, setData] = useState([]);
    const [currentRows, setCurrentRows] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState(-1);
    const [showLoading, setShowLoading] = useState(false);

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const sheetId = "1STi5oYHxoF4dWDLpzj0QYVIIFNrdPGDhF0i3kaZggSU";

    const { GoogleSpreadsheet } = require('google-spreadsheet');
    const creds = require('../foodpool-spreadsheet-206da2f9d6eb.json'); // the file saved above
    const doc = new GoogleSpreadsheet(sheetId);
    const link = "https://docs.google.com/spreadsheets/d/" + sheetId;

    useEffect(() => {
        async function getData() {
            await doc.useServiceAccountAuth(creds);
            await doc.loadInfo();
            setData(doc.sheetsByIndex);
            console.log("not authed");
        }
        auth.onAuthStateChanged((user) => {
            if (user) {
                //setUser(user)
                getData();
                setIsAuthed(true)
            }
        })
    }, []);

    // useEffect(() => {
    //     setShowLoading(true);
    //     return () => {
    //         setShowLoading(false);
    //     }
    // }, [data, selectedSheet, currentRows]);
    
    async function login(e) {
        e.preventDefault();
            // foodpooler!
            await setPersistence(auth, browserSessionPersistence);
            signInWithEmailAndPassword(auth, user, password).then(res => {

            setIsAuthed(true);
        }).catch(err => {
            console.log("not authed", err);
        })
    }

    function renderLogin() {
        if (!isAuthed) {
            return (
                <form onSubmit={login}>
                    <div>
                        <input type="text" value={user} onChange={(e) => setUser(e.target.value)} placeholder="username" />
                    </div>
                    <div>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                    </div>
                    <button type="submit">login</button>
                </form>
            );
        }
    }

    function renderShiftButtons() {
        if (!isAuthed) {
            return;
        }

        let sheetButtons = [];

        for (let i = 0; i < data.length; i++) {
            let sheet = data[i];

            if(selectedSheet === i) {
                sheetButtons.push(
                    <button key={i} className="selected" onClick={() => {getRows(i); setSelectedSheet(i);}}>{sheet.title}</button>
                );
            }
            else {
                sheetButtons.push(
                    <button key={i} className="" onClick={() => {getRows(i); setSelectedSheet(i);}}>{sheet.title}</button>
                );
            }

        }

        return (
            <div className="dashboard">
                <a href={link} target="_blank" rel="noreferrer" className="white"> View on Google Sheets</a>
                <div className="buttons">
                    {sheetButtons}
                </div>
            </div>
        );
    }

    async function getRows(i) {
        console.log(i, data[i]);

        let rows = await data[i].getRows();
        setCurrentRows(rows);
    }

    function renderSheet() {
        if (!isAuthed) return null;

        if (isAuthed && currentRows.length == 0) return (<div style={{margin:"1rem"}}>empty</div>);

        let rowList = [];

        let i = 0;

        for (const row of currentRows) {
            let name = row["Name/Nickname"].split(" ")[0];
            rowList.push(
                <div key={i} className="entry">
                    <div className="white">{row["Name/Nickname"]}</div>
                    <div>{row["Phone Number"]}</div>
                    <div className="green" style={{marginTop:"0.5rem"}}>Address:</div>
                    <div className="white" style={{paddingLeft:"0.5rem"}}>{row["Address + Any Special Instructions"]}</div>
                    <div className="green" style={{marginTop:"0.5rem"}}>Order:</div>
                    <div className="white" style={{paddingLeft:"0.5rem"}}> {row["Order + Any Special Instructions"]}</div>
                    <div className="entry-actions">
                        <a href={`sms:${row["Phone Number"]}&body=Hi ${name}, this is _ with your Foodpool order. Just letting you know that I've picked up your food and I'm on the way to drop it off.`}>Food</a>
                        <a href={`sms:${row["Phone Number"]}&body=Our ETA is _`}>ETA</a>
                        <a href={`sms:${row["Phone Number"]}`}>MSG</a>
                    </div>
                </div>
            );
            i++;
        }

        return (
            <div className="panel">
                <div>
                    {rowList}
                </div>
            </div>
        );
    }

    function showLoader(){
        if(showLoading) return (<div style={{margin:"1rem"}}>loading...</div>);
    }

    return (
        <div className="main">
            {renderLogin()}
            {renderShiftButtons()}
            {/* {showLoader()} */}
            {renderSheet()}
        </div>
    );

}