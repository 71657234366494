import "../styles/Main.scss"
import "../styles/RestaurantMenu.scss"
import { motion } from "framer-motion";
import MailForm from "../components/MailForm";
import FoodpoolLogo from "../images/foodpool_logo__icon_white_ai_1.svg";
import WaveDivider from "../components/WaveDivider";
import { fade } from "../Animations.js";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Button from "../components/Button";
import { useHistory, useParams } from 'react-router-dom';
import { trackMenuView, Medium } from '../analytics/menu.js';

import CanesMenu from "../menus/raising_canes.json";
import AlohaHawaiianMenu from "../menus/aloha_hawaiian.json";
import CaliFishGrillMenu from "../menus/cali_fish_grill.json";
import HironoriMenu from "../menus/hironori.json";
import HealthyBistroMenu from "../menus/bistro.json";
import MrFrenchCrepesMenu from "../menus/crepe.json";
import NickMenu from "../menus/nick.json";
import MrPicklesMenu from "../menus/pickles.json";
import UrbanPlatesMenu from "../menus/urban_plates.json";
import ChickfilaMenu from "../menus/chickfila.json";
import KokonutMenu from "../menus/kokonut.json";
import BjMenu from "../menus/bj.json";
import CheesetellaMenu from "../menus/cheesetella.json";
import SunrightMenu from "../menus/sunright.json";
import ChipotleMenu from "../menus/chipotle.json";
import IkesMenu from "../menus/ikes.json"; 
import CrumblMenu from "../menus/crumbl.json";
import CocoMenu from "../menus/coco.json";
import McdonaldsMenu from "../menus/mcdonalds.json";

// export const RestaurantMenu = (props) => {
export default function RestaurantMenu() {
    // trackMenuView(Medium.CURATED);
    const { id } = useParams()
    const menu = getMenu(id);
    return (
        <div className="main">
            <h2 className="white"style={{paddingTop:"6rem"}}> {menu.name}</h2>
            <motion.hr
                initial="hidden"
                whileInView="visible"
                variants={fade}
                viewport={{ once: true }}
                style={{width:"25%", backgroundColor:"#ce4855", height:"2px", borderWidth:"0"}}
            />

            <h3> Our Picks</h3>
            <p className="white"> (or see the full menu <a href={menu.link} target="_blank" rel="noreferrer" className="white">here</a>) </p>
            <p className="white"> Ready to order? Back to <a href="/menu" rel="noreferrer" className="white">weekly menu</a> </p>
            {loadMenu(id)}
            <p className="white not-on-desktop"> Ready to order? Back to <a href="/menu" target="_blank" rel="noreferrer" className="white">weekly menu</a> </p>
        </div >
    );
}

// https://docs.google.com/spreadsheets/d/13oNUYbBENBFAZxChYuyqsK42Fq8BrW-UwuNGYgmtZJE/edit#gid=0
function getMenu(id){
    switch(id){
        case "1":
            return CanesMenu;
        case "2":
            return AlohaHawaiianMenu;
        case "3":
            return CaliFishGrillMenu;
        case "4":
            return HironoriMenu;
        case "5":
            return HealthyBistroMenu;
        case "6":
            return MrFrenchCrepesMenu;
        case "7":
            return NickMenu;
        case "8":
            return MrPicklesMenu;
        case "9":
            return UrbanPlatesMenu;
        case "10":
            return ChickfilaMenu;
        case "11":
            return KokonutMenu;
        case "12":
            return BjMenu;
        case "13":
            return CheesetellaMenu;
        case "14":
            return SunrightMenu;
        case "15":
            return ChipotleMenu;
        case "16":
            return IkesMenu;
        case "17":
            return CrumblMenu;
        case "18":
            return CocoMenu;
        case "19":
            return McdonaldsMenu;
        default:
            return CanesMenu;
    }
}

function loadMenu(id){
    const menu = getMenu(id);
    return (
        <div className="menu-items">
            {menu.items[0].name != "" ? menu.items.map((item, index) => (
                <div key={index} className="item-card">
                    <img src={item.image} alt={item.name}/>
                    <h4 className="">{item.name} | {item.price}</h4>
                    <p className="white">{item.desc}</p>
                </div>
            ))
            :
            <p>We're working on it! Check out the restaurant's <a href={menu.link} target="_blank" rel="noreferrer" className="white">full menu</a> for now.</p>
            }
        </div>
    );
}
