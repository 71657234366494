import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import "../src/styles/index.scss";
import Header from './components/Header';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Landing from './pages/Landing';
import Menu from './pages/Menu';
import RestaurantMenu from './pages/RestaurantMenu';
// import initAnalytics from './analytics/init.js';
import Dashboard from "./pages/Dashboard";

function App() {
    // initAnalytics();
    
    return (
        <div className="App">
            <Banner/>
            <Header />
            <Router>
                <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route exact path="/menu" element={<Menu />} />
                    <Route path="/menu/:id" element={<RestaurantMenu/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>
            </Router>
            <Footer />
        </div >
    );

}

export default App;
