import '../styles/Header.scss';
import FoodpoolLogo from "../images/foodpool_logo__icon_white_ai_1.svg";

export default function Header() {
    return (
        <div className="header">
            <div className="logo">
                <img src={FoodpoolLogo} alt="Foodpool"></img>
                <h4>
                    <a href="/">
                        foodpool
                    </a>
                </h4>
            </div>
        </div>
    );
}