import mixpanel from 'mixpanel-browser';

const VIEW_EVENT = 'View Menu';

export class Medium {
    static SPREADSHEET = 'spreadsheet';
    static CURATED = 'curated';
}

export function trackMenuView(medium) {
    mixpanel.track(VIEW_EVENT, { medium : medium });
}
